angular.module('elogbooksServices').service('elbSettings', function ($window, lodash, apiClient) {

    'use strict';

    var self = this;
        this.settings = null;

    this.getSettings = function (reload, settingsUrl) {
        if (!$window.localStorage.getItem('settingsUrl') && !settingsUrl) {
            return;
        }

        if (!reload && $window.localStorage.getItem('settings')) {
            var settingsJson = $window.localStorage.getItem('settings');

            if (settingsJson !== 'undefined') {
                self.settings = angular.fromJson(settingsJson);
            }

            return self.settings;
        }

        return apiClient.get(settingsUrl || $window.localStorage.getItem('settingsUrl'), null, !reload)
            .then(function (response) {
                self.settings = response.settings;
                $window.localStorage.setItem('settings', angular.toJson(self.settings));

                return response;
            }, function () {
                // unable to call the user resource
                return false;
            });
    };

    this.getSetting = function (name) {
        return lodash.find(self.settings, {name:name});
    };

    this.init = function() {
        if ($window.localStorage.getItem('settings')) {
            var json = $window.localStorage.getItem('settings');
            if (json !== 'undefined') {
                self.settings = angular.fromJson(json);
            }
        }
    };

    this.init();

    /** @todo Load Settings */
    /** @todo Settings Form Fields Types */
    /** @todo Limit Settings */
});
